<template>
  <div
    class="flex items-center"
    :class="[{ 'flex-row-reverse': prefix }, { expand: isExpanded }]"
  >
    <input
      :value="value"
      type="text"
      class="focus:outline-none bg-transparent transition-all duration-300"
      :class="[inputClass, isExpanded ? 'w-full' : 'w-0']"
      autocomplete="new-search"
      :placeholder="placeholder"
      @input="emitTerm(($event.target as HTMLInputElement).value)"
    />
    <div class="w-2"></div>
    <v-icon icon="fa: fa-solid fa-magnifying-glass" :size="searchSizeIcon" :class="searchClass" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  inputClass: { type: String, default: '' },
  value: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  searchIcon: { type: String, default: 'search' },
  closeIcon: { type: String, default: 'close' },
  searchClass: { type: String, default: 'bg-transparent' },
  searchSizeIcon: { type: Number, default: 25 },
  closeClass: { type: String, default: 'w-5 h-5 cursor-pointer' },
  expandable: { type: Boolean, default: false },
  prefix: { type: Boolean, default: false },
  debounce: { type: Number, default: 500 }
})

const emit = defineEmits(['search-term'])
const expand = ref(false)
const isExpanded = computed(() => {
  if (props.expandable) {
    return expand.value
  }
  return true
})

const emitTerm = useDebounce((term?: string) => {
  emit('search-term', term)
}, props.debounce)

// function expandInput() {
//   if (props.expandable) expand.value = !expand.value;
// }
</script>
